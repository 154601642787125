import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const page404 = () => {
  return (
    <Layout>
      <SEO title="Page not found" />
      404
    </Layout>
  )
}

export default page404
